var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-img',{staticClass:"mx-auto",attrs:{"id":"OrgImage","src":_vm.logoUrl + this.$store.state.organization.s3 + '/Logo',"max-width":"300"}}),_c('h1',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(this.$store.state.organization.name)+" - "+_vm._s(_vm.$t("beginReport.title"))+" ")]),(this.$store.state.organization.orgId == 198854)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('beginReport.leppavaara_info'))}})]):(this.$store.state.organization.orgId === '426834')?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('beginReport.maarakennus_info'))}})]):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("beginReport.info_1")))]),_c('p',[_vm._v(_vm._s(_vm.$t("beginReport.info_2")))]),_c('p',[_vm._v(_vm._s(_vm.$t("beginReport.info_3")))])]),(
            this.$store.state.organization.groups != null &&
            this.$store.state.organization.groups.length > 1
          )?_c('v-select',{attrs:{"items":_vm.getGroupNames(),"label":_vm.$t('beginReport.selectGroup'),"rules":[(v) => !!v || 'beginReport.selectGroupAlertText'],"dense":"","outlined":"","required":""},on:{"change":function($event){return _vm.getGroup()}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}}):_vm._e(),(
            this.groupId != '' &&
            this.$store.state.organization.channelList[this.groupId] != null &&
            this.$store.state.organization.channelList[this.groupId].length > 1
          )?_c('v-select',{attrs:{"items":_vm.getChannelNames(),"label":_vm.$t('beginReport.selectChannel'),"rules":[(v) => !!v || 'beginReport.selectChannelAlertText'],"dense":"","outlined":"","required":""},on:{"change":function($event){return _vm.getChannel()}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_vm._v(_vm._s(_vm.$t(message)))]}}],null,false,3811468480),model:{value:(_vm.channelName),callback:function ($$v) {_vm.channelName=$$v},expression:"channelName"}}):_vm._e(),_c('v-btn',{staticClass:"my-8",staticStyle:{"color":"#fff","font-size":"1rem"},attrs:{"color":"#424752","depressed":""},on:{"click":function($event){return _vm.validate()}}},[_vm._v(" "+_vm._s(_vm.$t("beginReport.fillReport"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }