<template>
	<v-container>
		<v-row>
			<v-col>
				<v-img
					id="OrgImage"
					:src="logoUrl + this.$store.state.organization.s3 + '/Logo'"
					class="mx-auto"
					max-width="300"
				/>
				<h1 class="font-weight-medium">
					{{ this.$store.state.organization.name }} -
					{{ $t("beginReport.title") }}
				</h1>
				<div v-if="this.$store.state.organization.orgId == 198854">
					<p v-html="$t('beginReport.leppavaara_info')"></p>
				</div>
				<div v-else-if="this.$store.state.organization.orgId === '426834'">
					<p v-html="$t('beginReport.maarakennus_info')"></p>
				</div>
				<div v-else>
					<p>{{ $t("beginReport.info_1") }}</p>
					<p>{{ $t("beginReport.info_2") }}</p>
					<p>{{ $t("beginReport.info_3") }}</p>
				</div>
				<v-select
					v-if="
            this.$store.state.organization.groups != null &&
            this.$store.state.organization.groups.length > 1
          "
					v-model="groupName"
					:items="getGroupNames()"
					:label="$t('beginReport.selectGroup')"
					:rules="[(v) => !!v || 'beginReport.selectGroupAlertText']"
					dense
					outlined
					required
					@change="getGroup()"
				>
					<template #message="{ message }">{{ $t(message) }}</template>
				</v-select>
				<v-select
					v-if="
            this.groupId != '' &&
            this.$store.state.organization.channelList[this.groupId] != null &&
            this.$store.state.organization.channelList[this.groupId].length > 1
          "
					v-model="channelName"
					:items="getChannelNames()"
					:label="$t('beginReport.selectChannel')"
					:rules="[(v) => !!v || 'beginReport.selectChannelAlertText']"
					dense
					outlined
					required
					@change="getChannel()"
				>
					<template #message="{ message }">{{ $t(message) }}</template>
				</v-select>
				<v-btn
					class="my-8"
					color="#424752"
					depressed
					style="color: #fff; font-size: 1rem"
					@click="validate()"
				>
					{{ $t("beginReport.fillReport") }}
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
h1 {
	font-size: 28px;
	margin: 25px 0;
}

p {
	text-align: initial;
}
</style>

<script>
export default {
	data() {
		return {
			groupName: "",
			groupId: "",
			channelName: "",
			logoUrl: process.env.VUE_APP_VIHJAA_LOGOS,
		};
	},
	methods: {
		getGroupNames: function () {
			return this.$store.state.organization.groups.map((group) => group.name);
		},
		getGroup: function () {
			var group = this.$store.state.organization.groups.find(
				(group) => group.name === this.groupName
			);
			this.groupId = group.id;
			this.$store.commit("updateGroupId", group.id);
			if (
				this.$store.state.organization.channelList[this.groupId].length === 1
			) {
				this.setGroupDefaultChannel();
			} else if (
				this.$store.state.organization.channelList[this.groupId].length > 1
			) {
				this.$store.commit("updateChannelId", "");
				this.channelName = "";
			} else {
				this.$store.commit(
					"updateChannelId",
					this.$store.state.organization.defaultChannel.uuid
				);
			}
		},
		getChannelNames: function () {
			return this.$store.state.organization.channelList[this.groupId].map(
				(channel) => channel.name
			);
		},
		getChannel: function () {
			var channel = this.$store.state.organization.channelList[
				this.groupId
				].find((channel) => channel.name === this.channelName);
			this.$store.commit("updateChannelId", channel.uuid);
		},
		setGroupDefaultChannel: function () {
			var channel = this.$store.state.organization.channelList[this.groupId][0];
			this.$store.commit("updateChannelId", channel.uuid);
		},
		validate() {
			if (
				this.$store.state.channelId != null &&
				this.$store.state.channelId != ""
			) {
				this.$router.push(`/${this.$store.state.organization.urlName}/lomake`);
			} else {
				this.$store.commit("setSnack", {
					text:
						this.$i18n.locale == "fi"
							? "Valitse kanava listasta!"
							: "Select a channel from the list!",
					message: "",
					color: "error",
				});
			}
		},
	},
	mounted() {
		if (
			this.$store.state.organization.groups != null &&
			this.$store.state.organization.groups.length === 1
		) {
			this.groupId = this.$store.state.organization.groups[0].id;
			this.$store.commit("updateGroupId", this.groupId);
		}

		if (
			this.groupId != "" &&
			this.$store.state.organization.channelList[this.groupId] != null &&
			this.$store.state.organization.channelList[this.groupId].length === 1
		) {
			let channel = this.$store.state.organization.channelList[this.groupId][0];
			this.$store.commit("updateChannelId", channel.uuid);
		} else if (
			this.groupId != "" &&
			this.$store.state.organization.channelList[this.groupId] != null &&
			this.$store.state.organization.channelList[this.groupId].length === 0
		) {
			this.$store.commit(
				"updateChannelId",
				this.$store.state.organization.defaultChannel.uuid
			);
		}

		if (this.$store.state.groupId != "") {
			this.groupId = this.$store.state.groupId;
			if (this.$store.state.groupId !== this.$store.state.organization.orgId) {
				var group = this.$store.state.organization.groups.find(
					(group) => group.id === this.groupId
				);
				this.groupName = group.name;
			}
			if (this.$store.state.channelId != "") {
				var channel = this.$store.state.organization.channelList[
					this.groupId
					].find((channel) => channel.uuid === this.$store.state.channelId);
				this.channelName = channel.name;
			}
		}
	},
};
</script>